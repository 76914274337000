import { render, staticRenderFns } from "./AddHospital.vue?vue&type=template&id=098e9072&scoped=true"
import script from "./AddHospital.vue?vue&type=script&lang=js"
export * from "./AddHospital.vue?vue&type=script&lang=js"
import style0 from "./AddHospital.vue?vue&type=style&index=0&id=098e9072&scoped=true&lang=css"
import style1 from "./AddHospital.vue?vue&type=style&index=1&id=098e9072&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "098e9072",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/jissjohnson/code/Zenrion/CareKraft/zenrion-erp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('098e9072')) {
      api.createRecord('098e9072', component.options)
    } else {
      api.reload('098e9072', component.options)
    }
    module.hot.accept("./AddHospital.vue?vue&type=template&id=098e9072&scoped=true", function () {
      api.rerender('098e9072', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/admin/AddHospital.vue"
export default component.exports