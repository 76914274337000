/* This File is for {purpose} */

<template>
  <v-layout wrap justify-start fill-height>
    <v-flex xs12 px-2 px-sm-4 px-lg-8>
      <v-layout wrap justify-start py-8>
        <v-flex xs12 lg4>
          <v-layout wrap justify-start>
            <v-flex xs2 xl1>
              <v-avatar color="primary" size="31">
                <span class="avtr-txt"> 01 </span>
              </v-avatar>
            </v-flex>
            <v-flex xs10 xl11>
              <span class="title-2"> Enter basic details </span>
              <p class="subtitle-2">All fields are mandatory</p>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 lg6 offset-2>
          <v-form ref="form" lazy-validation>
            <v-layout wrap justify-start>
              <v-flex xs12 lg6 pa-lg-2 align-self-center>
                <p class="input-label">Name</p>
                <v-text-field
                  placeholder="Enter name"
                  dense
                  outlined
                  v-model="name"
                  :rules="[RULES.REQUIRED]"
                />
              </v-flex>
              <v-flex xs12 lg6 pt-4 pa-lg-2 align-self-start>
                <p class="input-label">Mobile Number</p>
                <v-text-field
                  placeholder="Enter Number"
                  dense
                  outlined
                  v-model="phone"
                  :rules="[RULES.REQUIRED]"
                />
              </v-flex>
              <v-flex xs12 lg6 pt-4 pa-lg-2 align-self-start>
                <p class="input-label">Email</p>
                <v-text-field
                  placeholder="Enter Email ID here"
                  dense
                  outlined
                  v-model="email"
                  :rules="[RULES.REQUIRED]"
                />
              </v-flex>
              <v-flex xs12 lg6 pt-4 pa-lg-2 align-self-center>
                <p class="input-label">Address</p>
                <v-textarea
                  rows="4"
                  placeholder="Enter text here"
                  dense
                  outlined
                  v-model="address"
                  :rules="[RULES.REQUIRED]"
                />
              </v-flex>
            </v-layout>
          </v-form>
        </v-flex>
      </v-layout>
      <!--        <v-divider />-->
      <!--        <v-layout wrap justify-start py-8>-->
      <!--          <v-flex xs12 lg4>-->
      <!--            <v-layout wrap justify-start>-->
      <!--              <v-flex xs2 xl1>-->
      <!--                <v-avatar color="primary" size="31">-->
      <!--                  <span class="avtr-txt"> 02 </span>-->
      <!--                </v-avatar>-->
      <!--              </v-flex>-->
      <!--              <v-flex xs10 xl11>-->
      <!--                <span class="title-2">Hospital details</span>-->
      <!--                <p class="subtitle-2">All fields are mandatory</p>-->
      <!--              </v-flex>-->
      <!--            </v-layout>-->
      <!--          </v-flex>-->
      <!--          <v-flex xs12 lg6 offset-2>-->
      <!--            <v-layout wrap justify-start>-->
      <!--              <v-flex xs12 lg6 pa-lg-2>-->
      <!--                <p class="input-label">Qualification</p>-->
      <!--                <v-select-->
      <!--                  placeholder="Select Qualification"-->
      <!--                  outlined-->
      <!--                  dense-->
      <!--                  hide-details-->
      <!--                ></v-select>-->
      <!--              </v-flex>-->
      <!--              <v-flex xs12 lg6 pt-4 pa-lg-2 align-self-center>-->
      <!--                <p class="input-label">Department</p>-->
      <!--                <v-select-->
      <!--                  placeholder="Select Department"-->
      <!--                  outlined-->
      <!--                  dense-->
      <!--                  hide-details-->
      <!--                ></v-select>-->
      <!--              </v-flex>-->
      <!--            </v-layout>-->
      <!--          </v-flex>-->
      <!--        </v-layout>-->
      <!--        <v-divider />-->
      <!--        <v-layout wrap justify-start py-8>-->
      <!--          <v-flex xs12 lg4>-->
      <!--            <v-layout wrap justify-start>-->
      <!--              <v-flex xs2 xl1>-->
      <!--                <v-avatar color="primary" size="31">-->
      <!--                  <span class="avtr-txt"> 02 </span>-->
      <!--                </v-avatar>-->
      <!--              </v-flex>-->
      <!--              <v-flex xs10 xl11>-->
      <!--                <span class="title-2">-->
      <!--                  Enter employee details-->
      <!--                  <span class="subtitle-1">(optional)</span>-->
      <!--                </span>-->
      <!--              </v-flex>-->
      <!--            </v-layout>-->
      <!--          </v-flex>-->
      <!--          <v-flex xs12 lg6 offset-2 style="height: 300px">-->
      <!--            <v-layout wrap justify-center fill-height pa-2>-->
      <!--              <v-flex-->
      <!--                xs12-->
      <!--                align-self-center-->
      <!--                text-center-->
      <!--                class="border-dotted"-->
      <!--                style="height: 100%"-->
      <!--              >-->
      <!--                <v-layout wrap justify-center fill-height>-->
      <!--                  <v-flex xs12 align-self-center>-->
      <!--                    <v-img-->
      <!--                      src="@/assets/images/file.svg"-->
      <!--                      height="50px"-->
      <!--                      contain-->
      <!--                    ></v-img>-->
      <!--                    <span class="subtitle-2">Select a CSV file to upload</span>-->
      <!--                  </v-flex>-->
      <!--                </v-layout>-->
      <!--              </v-flex>-->
      <!--            </v-layout>-->
      <!--          </v-flex>-->
      <!--        </v-layout>-->
    </v-flex>
    <v-flex xs12 align-self-end>
      <v-card tile height="100%">
        <v-layout wrap justify-start fill-height py-8>
          <v-flex xs12 px-8 text-right align-self-center>
            <span class="px-4">
              <AppNavButton
                :route="{ name: 'hospitals' }"
                color="primary"
                custom-class="pa-5"
                outlined
              >
                <template #content>
                  <span style="color: #3278ff"> Cancel </span>
                </template>
              </AppNavButton>
            </span>

            <span class="px-4">
              <AppButton @submit="manageForm" custom-class="pa-5">
                <template #content>
                  {{ $t(getButtonLabel) }}
                </template>
              </AppButton>
            </span>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
    <SuccessConfirmation
      v-model="showSuccess"
      :title="successTitle"
      :type="successType"
      @submit="$router.go(-1)"
      @cancel="toggleSuccess"
    />
  </v-layout>
</template>

<script>
import { RULES } from "@/libs/helper/components/rules";

export default {
  name: "AddHospital",
  data: () => ({
    RULES,
    showSuccess: false,
    name: null,
    phone: null,
    email: null,
    address: null,
  }),
  computed: {
    getButtonLabel() {
      return this.$route.params.id
        ? "pages.hospital.update_hospital"
        : "pages.hospital.add_hospital";
    },
    successTitle() {
      return this.$route.params.id ? "Hospital Updated" : "Hospital Added";
    },
    successType() {
      return this.$route.params.id ? "edit" : "hospital";
    },
  },
  created() {
    if (this.$route.params.id) this.getData();
  },
  mounted() {
    const breadCrumbs = [
      {
        name: "hospitals",
        text: "Hospitals",
      },
      {
        name: this.$route.name,
        text: this.$route.params.id
          ? "pages.hospital.update_hospital"
          : "pages.hospital.add_hospital",
      },
    ];
    this.$store.commit("app/setBreadCrumbs", breadCrumbs);
  },
  methods: {
    toggleSuccess() {
      this.showSuccess = !this.showSuccess;
    },
    getData() {
      this.$store
        .dispatch("hospital/getHospital", this.$route.params.id)
        .then((res) => {
          this.name = res.name;
          this.phone = res.phone;
          this.email = res.email;
          this.address = res.address;
        });
    },
    manageForm() {
      if (this.$route.params.id) this.editHospital();
      else this.addHospital();
    },
    editHospital() {
      if (!this.$refs.form.validate()) return;
      const data = {
        id: this.$route.params.id,
        name: this.name,
        phone: this.phone,
        email: this.email,
        address: this.address,
        default_locale: "de",
      };
      this.$store
        .dispatch("hospital/editHospital", data)
        .then(() => {
          this.toggleSuccess();
        })
        .catch((err) => {
          console.log(err);
          this.message = err.response.data.message;
          this.snackbarColor = "error";
          this.showSnackbar = true;
        });
    },
    addHospital() {
      if (!this.$refs.form.validate()) return;
      const data = {
        name: this.name,
        phone: this.phone,
        email: this.email,
        address: this.address,
        default_locale: "de",
      };
      this.$store
        .dispatch("hospital/addHospital", data)
        .then(() => {
          this.$refs.form.reset();
          this.toggleSuccess();
        })
        .catch((err) => {
          console.log(err);
          this.message = err.response.data.message;
          this.snackbarColor = "error";
          this.showSnackbar = true;
        });
    },
  },
};
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #c0cbe0 !important;
}

.v-text-field--outlined::v-deep input::placeholder,
.v-text-field--outlined::v-deep textarea::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #9fadc8;
}

.v-input ::v-deep input,
.v-input ::v-deep textarea,
.v-input ::v-deep .v-select__selection {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #081734;
}

.v-input ::v-deep label {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #081734;
}
</style>
<style>
.border-dotted {
  border: 2px dashed #3278ff;
  border-radius: 7px;
}
</style>
