var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    { attrs: { wrap: "", "justify-start": "", "fill-height": "" } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "", "px-2": "", "px-sm-4": "", "px-lg-8": "" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "", "justify-start": "", "py-8": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", lg4: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "", "justify-start": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs2: "", xl1: "" } },
                        [
                          _c(
                            "v-avatar",
                            { attrs: { color: "primary", size: "31" } },
                            [
                              _c("span", { staticClass: "avtr-txt" }, [
                                _vm._v(" 01 "),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("v-flex", { attrs: { xs10: "", xl11: "" } }, [
                        _c("span", { staticClass: "title-2" }, [
                          _vm._v(" Enter basic details "),
                        ]),
                        _c("p", { staticClass: "subtitle-2" }, [
                          _vm._v("All fields are mandatory"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "", lg6: "", "offset-2": "" } },
                [
                  _c(
                    "v-form",
                    { ref: "form", attrs: { "lazy-validation": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "", "justify-start": "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                lg6: "",
                                "pa-lg-2": "",
                                "align-self-center": "",
                              },
                            },
                            [
                              _c("p", { staticClass: "input-label" }, [
                                _vm._v("Name"),
                              ]),
                              _c("v-text-field", {
                                attrs: {
                                  placeholder: "Enter name",
                                  dense: "",
                                  outlined: "",
                                  rules: [_vm.RULES.REQUIRED],
                                },
                                model: {
                                  value: _vm.name,
                                  callback: function ($$v) {
                                    _vm.name = $$v
                                  },
                                  expression: "name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                lg6: "",
                                "pt-4": "",
                                "pa-lg-2": "",
                                "align-self-start": "",
                              },
                            },
                            [
                              _c("p", { staticClass: "input-label" }, [
                                _vm._v("Mobile Number"),
                              ]),
                              _c("v-text-field", {
                                attrs: {
                                  placeholder: "Enter Number",
                                  dense: "",
                                  outlined: "",
                                  rules: [_vm.RULES.REQUIRED],
                                },
                                model: {
                                  value: _vm.phone,
                                  callback: function ($$v) {
                                    _vm.phone = $$v
                                  },
                                  expression: "phone",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                lg6: "",
                                "pt-4": "",
                                "pa-lg-2": "",
                                "align-self-start": "",
                              },
                            },
                            [
                              _c("p", { staticClass: "input-label" }, [
                                _vm._v("Email"),
                              ]),
                              _c("v-text-field", {
                                attrs: {
                                  placeholder: "Enter Email ID here",
                                  dense: "",
                                  outlined: "",
                                  rules: [_vm.RULES.REQUIRED],
                                },
                                model: {
                                  value: _vm.email,
                                  callback: function ($$v) {
                                    _vm.email = $$v
                                  },
                                  expression: "email",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                lg6: "",
                                "pt-4": "",
                                "pa-lg-2": "",
                                "align-self-center": "",
                              },
                            },
                            [
                              _c("p", { staticClass: "input-label" }, [
                                _vm._v("Address"),
                              ]),
                              _c("v-textarea", {
                                attrs: {
                                  rows: "4",
                                  placeholder: "Enter text here",
                                  dense: "",
                                  outlined: "",
                                  rules: [_vm.RULES.REQUIRED],
                                },
                                model: {
                                  value: _vm.address,
                                  callback: function ($$v) {
                                    _vm.address = $$v
                                  },
                                  expression: "address",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-flex",
        { attrs: { xs12: "", "align-self-end": "" } },
        [
          _c(
            "v-card",
            { attrs: { tile: "", height: "100%" } },
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    wrap: "",
                    "justify-start": "",
                    "fill-height": "",
                    "py-8": "",
                  },
                },
                [
                  _c(
                    "v-flex",
                    {
                      attrs: {
                        xs12: "",
                        "px-8": "",
                        "text-right": "",
                        "align-self-center": "",
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "px-4" },
                        [
                          _c("AppNavButton", {
                            attrs: {
                              route: { name: "hospitals" },
                              color: "primary",
                              "custom-class": "pa-5",
                              outlined: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "content",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#3278ff" } },
                                      [_vm._v(" Cancel ")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        { staticClass: "px-4" },
                        [
                          _c("AppButton", {
                            attrs: { "custom-class": "pa-5" },
                            on: { submit: _vm.manageForm },
                            scopedSlots: _vm._u([
                              {
                                key: "content",
                                fn: function () {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t(_vm.getButtonLabel)) +
                                        " "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("SuccessConfirmation", {
        attrs: { title: _vm.successTitle, type: _vm.successType },
        on: {
          submit: function ($event) {
            return _vm.$router.go(-1)
          },
          cancel: _vm.toggleSuccess,
        },
        model: {
          value: _vm.showSuccess,
          callback: function ($$v) {
            _vm.showSuccess = $$v
          },
          expression: "showSuccess",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }